import * as React from "react";
import {
  Link,
  VStack,
  useColorMode,
  Text,
  HStack,
  Badge,
  Flex,
} from "@chakra-ui/react";
import { NavLink as ReactRouterLink } from "react-router-dom";

interface SideNavProps {
  publishCount: number;
}

const SideNav = ({ publishCount }: SideNavProps) => {
  const { colorMode } = useColorMode();
  const activeLink = {
    opacity: 1,
    backgroundColor: colorMode === "dark" ? "blue.700" : "blue.50",
  };
  return (
    <Flex direction="column" h="full">
      <VStack spacing={4} alignItems="flex-stretch">
        <Link
          opacity="0.8"
          rounded="lg"
          px={4}
          py={3}
          as={ReactRouterLink}
          to="/configuration"
          _activeLink={activeLink}
        >
          Tenant Configuration
        </Link>
        <Link
          opacity="0.8"
          rounded="lg"
          px={4}
          py={3}
          as={ReactRouterLink}
          to="/publish"
          _activeLink={activeLink}
        >
          <HStack spacing={3}>
            <Text>Publish</Text>
            {publishCount > 0 && (
              <Badge
                _hover={{ textDecoration: "none" }}
                colorScheme="orange"
                rounded="sm"
              >
                {publishCount}
              </Badge>
            )}
          </HStack>
        </Link>
        <Link
          opacity="0.8"
          rounded="lg"
          px={4}
          py={3}
          as={ReactRouterLink}
          to="/entitlements"
          _activeLink={activeLink}
        >
          Entitlements
        </Link>
      </VStack>
    </Flex>
  );
};

export default SideNav;
