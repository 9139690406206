const configs = [
  "AccountPrefixConfig",
  "AccountVelocityConfig",
  "AdaptiveListConfig",
  "AnalysisVersionConfig",
  "AniVelocityConfig",
  "AutomaticCaseCreationConfig",
  "AwsConnectCaptureConfig",
  "BlacklistConfig",
  "CallCaptureConfigSet",
  "CallCaptureIPMapConfig",
  "CallPhaseListenerConfig",
  "CallSupervisorConfig",
  "CallSupervisorSystemSet",
  "CarrierRiskLabels",
  "CarrierSpoofDetectionConfig",
  "CaseCustomStatusConfig",
  "CaseFraudTypeConfig",
  "ConsortiumCredential",
  "CustomerInstanceCountConfig",
  "DatalakePublisherConfig",
  "DeviceEnsembleConfig",
  "EnsembleConfigs",
  "ExtractorExclusionConfig",
  "FraudGrouperConfig",
  "FraudNetworkConfig",
  "IdentityClaimConfig",
  "IVRAccountRiskConfig",
  "Level3EnsemblePredictorConfig",
  "LineOfBusinessConfig",
  "NightlyReporterConfig",
  "OrgTimeZoneConfig",
  "PhoneNumberConfig",
  "PrsDataSystemConfigs",
  "PrsTokenCredential",
  "RecordingConfig",
  "ResellerConfig",
  "RiskFactorConfigs",
  "RiskWeightConfigs",
  "SafeExtractionConfigs",
  "SafeLabelModelConfigs",
  "SafeprintBlacklistModelConfigs",
  "SandboxCustomerConfig",
  "ScoreCalibratorConfig",
  "ScoreReportingThresholdConfigs",
  "SourceListConfig",
  "SpoofprintModelConfigs",
  "SvarExtractionConfig",
  "SvarExtractionConfigs",
  "SvarFactoryConfig",
  "VoiceprintBlacklistModelConfigs",
  "WhitelistConfig",
];
configs.sort((a, b) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
});
export default function getConfigs() {
  return configs;
}
