import {
  Grid,
  GridItem,
  Text,
  Button,
  Heading,
  useColorModeValue,
  Flex,
  Spacer,
  Select,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { RiDeleteBin4Line } from "react-icons/ri";
import * as React from "react";
import useEntitlementsLookup, { Entitlement } from "./useEntitlementsLookup";

interface EntitlementsProps {
  entitlementIds?: string[];
  token?: string;
  customerId?: string;
  blockProdSubmit: boolean;
}

const Entitlements = ({
  token,
  customerId,
  blockProdSubmit,
}: EntitlementsProps) => {
  const {
    isLoadingOrgEntitlements,
    orgEntitlementData,
    allEntitlements,
    addEntitlementToOrg,
    removeEntitlementFromOrg,
  } = useEntitlementsLookup({
    token,
    orgId: customerId,
  });
  const [isRemovingMap, setIsRemovingMap] = React.useState<
    Record<string, boolean>
  >({});
  const borderColor = useColorModeValue("gray.100", "inherit");
  const toast = useToast();

  const handleRemove = async (entId: string) => {
    setIsRemovingMap((cur) => ({ ...cur, [entId]: true }));
    try {
      await removeEntitlementFromOrg({ entId });
      toast({
        status: "success",
        description: "Entitlement removed",
        duration: 5000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        status: "error",
        description: "Unable to remove entitlement",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsRemovingMap((cur) => ({ ...cur, [entId]: false }));
    }
  };
  return (
    <Grid
      templateColumns="1fr 300px"
      templateRows="minmax(0, 1fr)"
      h="full"
      gap={6}
    >
      <GridItem overflow="auto" h="full">
        <Heading as="h2" size="md">
          Active Entitlements
        </Heading>
        <Grid templateColumns="1fr 1fr 1fr" gap={6} mt={6}>
          {isLoadingOrgEntitlements && <Spinner />}
          {orgEntitlementData?.map((ent) => (
            <GridItem
              p={4}
              rounded="md"
              boxShadow="md"
              key={ent?.id}
              border="1px solid"
              borderColor={borderColor}
            >
              <Flex direction="column" h="full">
                <Text fontSize="lg">{ent?.name}</Text>
                <Text mt={2} fontWeight="300">
                  {ent?.description}
                </Text>
                <Spacer />
                <Button
                  disabled={blockProdSubmit}
                  mt={3}
                  variant="ghost"
                  colorScheme="red"
                  leftIcon={<RiDeleteBin4Line />}
                  isLoading={isRemovingMap[ent?.id ?? ""]}
                  onClick={() => {
                    if (!ent) return;
                    handleRemove(ent.id);
                  }}
                >
                  Remove
                </Button>
              </Flex>
            </GridItem>
          ))}
        </Grid>
      </GridItem>
      <GridItem>
        <AddEntitlementForm
          allEntitlements={allEntitlements}
          addEntitlementToOrg={addEntitlementToOrg}
          blockProdSubmit={blockProdSubmit}
        />
      </GridItem>
    </Grid>
  );
};

const AddEntitlementForm = ({
  allEntitlements,
  addEntitlementToOrg,
  blockProdSubmit,
}: {
  allEntitlements?: Entitlement[];
  addEntitlementToOrg: ({ entId }: { entId: string }) => Promise<void>;
  blockProdSubmit: boolean;
}) => {
  const toast = useToast();
  const [selectedEntitlement, setSelectedEntitlement] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const handleSubmit = async () =>
    addEntitlementToOrg({ entId: selectedEntitlement });
  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        setSubmitting(true);
        await handleSubmit();
        toast({
          status: "success",
          description: "Entitlement added to tenant",
          duration: 5000,
          isClosable: true,
        });
        setSubmitting(false);
      }}
    >
      <Heading as="h3" size="sm" mb={6}>
        Add an Entitlement
      </Heading>
      <Select onChange={({ target }) => setSelectedEntitlement(target.value)}>
        <option value="">Select an entitlement</option>
        {allEntitlements?.map((ent) => (
          <option key={ent.id} value={ent.id}>
            {ent.name}
          </option>
        ))}
      </Select>
      <Text h={24} py={4}>
        {allEntitlements?.find((ent) => ent.id === selectedEntitlement)
          ?.description ?? "Select an entitlement to learn more"}
      </Text>
      <Button
        colorScheme="teal"
        w="full"
        type="submit"
        isLoading={submitting}
        disabled={blockProdSubmit}
      >
        Add to tenant
      </Button>
    </form>
  );
};

export default Entitlements;
