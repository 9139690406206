import * as React from "react";

const prodEndpoints = {
  iam: "https://us.iam.pindrop.io/v1",
  api: "https://api.pindrop.io/v1",
};

const prod2Endpoints = {
  iam: "https://us.iam.pindrop.io/v1",
  api: "https://us2.api.pindrop.io/v1",
};

const caProdEndpoints = {
  iam: "https://ca.iam.pindrop.io/v1",
  api: "https://ca.api.pindrop.io/v1",
};

const preprodEndpoints = {
  iam: "https://staging.us.iam.pindrop.io/v1",
  api: "https://pre-prod.api.pindrop.io/v1",
};

const stagingEndpoints = {
  iam: "https://staging.us.iam.pindrop.io/v1",
  api: "https://staging.api.pindrop.io/v1",
};

type envOptions =
  | "staging"
  | "pre-prod"
  | "production"
  | "production-2"
  | "ca-production";

export const isProduction = (val: string) => val.includes("production");

export const EnvironmentContext = React.createContext({
  iam: "",
  api: "",
  setEndpoints: (s: envOptions) => {},
  current: "",
});

const getEndpoints = (env: envOptions) => {
  switch (env) {
    case "staging":
      return stagingEndpoints;
    case "pre-prod":
      return preprodEndpoints;
    case "production":
      return prodEndpoints;
    case "production-2":
      return prod2Endpoints;
    case "ca-production":
      return caProdEndpoints;
  }
};

const EnvironmentProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [current, setCurrent] = React.useState(
    window.localStorage.getItem("current-env") || "production"
  );
  const [endpoints, setEndpoints] = React.useState(
    getEndpoints(
      (window.localStorage.getItem("current-env") as envOptions) || "production"
    )
  );

  const handleEndpointsChange = (env: envOptions) => {
    switch (env) {
      case "staging":
        setEndpoints(stagingEndpoints);
        setCurrent("staging");
        window.localStorage.setItem("current-env", "staging");
        break;
      case "pre-prod":
        setEndpoints(preprodEndpoints);
        setCurrent("pre-prod");
        window.localStorage.setItem("current-env", "pre-prod");
        break;
      case "production":
        setEndpoints(prodEndpoints);
        setCurrent("production");
        window.localStorage.setItem("current-env", "production");
        break;
      case "production-2":
        setEndpoints(prod2Endpoints);
        setCurrent("US production 2");
        window.localStorage.setItem("current-env", "US production 2");
        break;
      case "ca-production":
        setEndpoints(caProdEndpoints);
        setCurrent("CA production");
        window.localStorage.setItem("current-env", "CA production");
        break;
    }
  };
  return (
    <EnvironmentContext.Provider
      value={{ ...endpoints, setEndpoints: handleEndpointsChange, current }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

export default EnvironmentProvider;
