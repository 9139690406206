import * as React from "react";
import { API } from "aws-amplify";
import { EnvironmentContext } from "../EnvironmentProvider";

export interface Entitlement {
  id: string;
  name: string;
  short_name: string;
  description: string;
  created_on: string;
}

export interface OrgEntitlementRecord {
  id: string;
  entitlement_id: string;
}

const addEntitlementToOrg = async ({
  iamUrl,
  orgId,
  entId,
  token,
}: {
  iamUrl: string;
  orgId: string;
  entId: string;
  token?: string;
}): Promise<OrgEntitlementRecord | undefined> => {
  const tokenForUse = token || window.localStorage.getItem("config-app-token");
  if (!tokenForUse) return;
  const { data } = await API.post("proxyAPI", "/proxy", {
    headers: {
      Authorization: `Bearer ${tokenForUse}`,
    },
    queryStringParameters: {
      url: `${iamUrl}/org_entitlement`,
    },
    body: {
      org_id: orgId,
      entitlement_id: entId,
    },
  });
  return data;
};

const getEntitlement = async ({
  iamUrl,
  id,
  token,
}: {
  iamUrl: string;
  id: string;
  token?: string;
}): Promise<Entitlement | undefined> => {
  const tokenForUse = token || window.localStorage.getItem("config-app-token");
  if (!tokenForUse) return;
  const { data } = await API.get("proxyAPI", "/proxy", {
    headers: {
      Authorization: `Bearer ${tokenForUse}`,
    },
    queryStringParameters: {
      url: `${iamUrl}/entitlement/${id}`,
    },
  });
  return data;
};

const getAllEntitlements = async ({
  iamUrl,
  token,
}: {
  iamUrl: string;
  token?: string;
}): Promise<Entitlement[]> => {
  const tokenForUse = token || window.localStorage.getItem("config-app-token");
  const { data } = await API.get("proxyAPI", "/proxy", {
    headers: {
      Authorization: `Bearer ${tokenForUse}`,
    },
    queryStringParameters: {
      url: `${iamUrl}/entitlement`,
    },
  });
  return data;
};

const deleteEntitlementFromOrg = async ({
  id,
  iamUrl,
  token,
}: {
  iamUrl: string;
  token?: string;
  id: string;
}): Promise<OrgEntitlementRecord | undefined> => {
  const tokenForUse = token || window.localStorage.getItem("config-app-token");
  if (!tokenForUse) return;
  const { data } = await API.del("proxyAPI", "/proxy", {
    headers: {
      Authorization: `Bearer ${tokenForUse}`,
    },
    queryStringParameters: {
      url: `${iamUrl}/org_entitlement/${id}`,
    },
  });
  return data;
};

const getOrgEntitlementIds = async ({
  iamUrl,
  token,
  orgId,
}: {
  iamUrl: string;
  token?: string;
  orgId: string;
}): Promise<OrgEntitlementRecord[]> => {
  const tokenForUse = token || window.localStorage.getItem("config-app-token");
  const { data } = await API.get("proxyAPI", "/proxy", {
    headers: {
      Authorization: `Bearer ${tokenForUse}`,
    },
    queryStringParameters: {
      url: `${iamUrl}/org_entitlement?filter=org_id EQ ${orgId}`,
    },
  });
  return data;
};

export default function useEntitlementsLookup({
  token,
  orgId = "",
}: {
  token?: string;
  orgId?: string;
}) {
  const { iam } = React.useContext(EnvironmentContext);
  const [
    isLoadingOrgEntitlements,
    setIsLoadingOrgEntitlements,
  ] = React.useState(true);
  const [orgEntitlementData, setOrgEntitlementData] = React.useState<
    (Entitlement | undefined)[]
  >();
  const [allEntitlements, setAllEntitlements] = React.useState<Entitlement[]>();
  const [orgEntitlementRecords, setOrgEntitlementRecords] = React.useState<
    OrgEntitlementRecord[]
  >([]);

  React.useEffect(() => {
    getAllEntitlements({ iamUrl: iam, token }).then((data) => {
      if (data) {
        setAllEntitlements(data);
      }
    });
  }, [token, iam]);

  React.useEffect(() => {
    if (!orgId) return;
    getOrgEntitlementIds({ iamUrl: iam, token, orgId }).then((data) => {
      if (data) {
        setOrgEntitlementRecords(data);
      }
      setIsLoadingOrgEntitlements(false);
    });
  }, [token, iam, orgId]);

  React.useEffect(() => {
    async function getEntitlements() {
      const results = await Promise.all(
        orgEntitlementRecords.map(({ entitlement_id }) => {
          return getEntitlement({ id: entitlement_id, iamUrl: iam, token })
            .then((data) => {
              return data;
            })
            .catch((e) => {
              console.warn(e);
              return undefined;
            });
        })
      );
      setOrgEntitlementData(results?.filter(Boolean) ?? []);
    }
    if (orgEntitlementRecords?.length === 0) return;
    getEntitlements();
  }, [iam, orgEntitlementRecords, token]);

  return {
    isLoadingOrgEntitlements,
    orgEntitlementData,
    allEntitlements,
    addEntitlementToOrg: async ({ entId }: { entId: string }) => {
      const data = await addEntitlementToOrg({
        iamUrl: iam,
        token,
        orgId,
        entId,
      });
      if (data) {
        setOrgEntitlementRecords((cur) => cur.concat(data));
      }
      return;
    },
    removeEntitlementFromOrg: async ({ entId }: { entId: string }) => {
      const record = orgEntitlementRecords.find(
        (record) => record.entitlement_id === entId
      );
      if (!record) return;
      await deleteEntitlementFromOrg({
        id: record.id,
        token,
        iamUrl: iam,
      });
      setOrgEntitlementRecords((cur) => cur.filter((r) => r.id !== record.id));
      return;
    },
  };
}
