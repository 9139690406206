import * as React from "react";
import {
  Badge,
  Flex,
  Spacer,
  Button,
  HStack,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { RiPencilLine } from "react-icons/ri";
import { AuthContext } from "./Auth";
import { EnvironmentContext } from "./EnvironmentProvider";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import useOrgLookup from "./useOrgLookup";

const TopBar = ({
  customerId,
  customerName,
  token,
  openSettings,
}: {
  customerId?: string;
  customerName?: string;
  token: string;
  openSettings: () => void;
}) => {
  const { logout } = React.useContext(AuthContext);
  const { current } = React.useContext(EnvironmentContext);
  return (
    <Flex alignItems="center" py={4}>
      <HStack spacing={4} ml={4}>
        <Badge colorScheme="green" rounded="sm">
          {current}
        </Badge>
        {customerId && (
          <Badge colorScheme="blue" rounded="sm">
            <Text isTruncated maxW="sm">
              {customerName || customerId}
            </Text>
          </Badge>
        )}
        {token && (
          <Badge colorScheme="indigo" rounded="sm">
            <Text isTruncated maxW="sm">
              {token}
            </Text>
          </Badge>
        )}
        {customerId && (
          <IconButton
            icon={<RiPencilLine />}
            aria-label="Open the settings options"
            onClick={openSettings}
            size="sm"
            variant="ghost"
          />
        )}
      </HStack>
      <Spacer />
      <ColorModeSwitcher />
      <Button ml={3} variant="outline" colorScheme="orange" onClick={logout}>
        Sign Out
      </Button>
    </Flex>
  );
};

export default TopBar;
