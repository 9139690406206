/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProdChange = /* GraphQL */ `
  mutation CreateProdChange(
    $input: CreateProdChangeInput!
    $condition: ModelProdChangeConditionInput
  ) {
    createProdChange(input: $input, condition: $condition) {
      id
      user
      cm
      configName
      environment
      customerId
      createdAt
      updatedAt
    }
  }
`;
export const updateProdChange = /* GraphQL */ `
  mutation UpdateProdChange(
    $input: UpdateProdChangeInput!
    $condition: ModelProdChangeConditionInput
  ) {
    updateProdChange(input: $input, condition: $condition) {
      id
      user
      cm
      configName
      environment
      customerId
      createdAt
      updatedAt
    }
  }
`;
export const deleteProdChange = /* GraphQL */ `
  mutation DeleteProdChange(
    $input: DeleteProdChangeInput!
    $condition: ModelProdChangeConditionInput
  ) {
    deleteProdChange(input: $input, condition: $condition) {
      id
      user
      cm
      configName
      environment
      customerId
      createdAt
      updatedAt
    }
  }
`;
