import * as React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Select,
  Input,
  FormHelperText,
  useToast,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { ArrowForwardIcon, ArrowBackIcon } from "@chakra-ui/icons";
import { useMachine } from "@xstate/react";
import { Machine } from "xstate";
import useUserLookup from "./useUserLookup";
import { EnvironmentContext, isProduction } from "./EnvironmentProvider";

interface SettingsProps {
  token: string;
  setToken: (val: string) => void;
  customerId: string;
  setCustomerId: (val: string) => void;
  isOpen: boolean;
  onClose: () => void;
  cm: string;
  setCm: (val: string) => void;
  setBlockProdSubmit: (val: boolean) => void;
}

const customerIdMachine = Machine({
  id: "config-settings-view",
  initial: "dropdown",
  states: {
    dropdown: {
      on: { TOGGLE: "custom" },
    },
    custom: {
      on: { TOGGLE: "dropdown" },
    },
  },
});

const Settings = ({
  token,
  setToken,
  customerId,
  setCustomerId,
  isOpen,
  onClose,
  cm,
  setCm,
  setBlockProdSubmit,
}: SettingsProps) => {
  const user = useUserLookup();
  const [idState, sendId] = useMachine(customerIdMachine);
  const toast = useToast();

  const { current } = React.useContext(EnvironmentContext);

  const handleClose = () => {
    if (!customerId) {
      toast({
        status: "info",
        title: "Tenant not set",
        description: "You must specify a tenant",
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        status: "success",
        description: "Settings saved!",
        duration: 5000,
        isClosable: true,
      });
      if (isProduction(current) && !cm) {
        setBlockProdSubmit(true);
      }
      onClose();
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>Edit what tenant and token are used</Text>
            <FormControl id="customer id" isRequired>
              {idState.value === "dropdown" && (
                <>
                  <FormLabel>Tenant</FormLabel>
                  <FormHelperText>
                    This list is populated by your user's assigned organizations
                  </FormHelperText>
                  <Select
                    placeholder="Select tenant"
                    value={customerId}
                    onChange={(e) => setCustomerId(e.target.value)}
                    mt={2}
                  >
                    {user?.assigned_orgs.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <Button
                    onClick={() => sendId("TOGGLE")}
                    variant="link"
                    rightIcon={<ArrowForwardIcon />}
                    mt={2}
                  >
                    Or specify your own
                  </Button>
                </>
              )}
              {idState.value === "custom" && (
                <>
                  <FormLabel>Tenant ID</FormLabel>
                  <Input
                    value={customerId}
                    onChange={({ target }) => setCustomerId(target.value)}
                  />
                  <Button
                    onClick={() => sendId("TOGGLE")}
                    variant="link"
                    leftIcon={<ArrowBackIcon />}
                    mt={2}
                  >
                    Choose from assigned organizations list
                  </Button>
                </>
              )}
            </FormControl>
            <FormControl
              id="token"
              mt={6}
              isRequired={idState.value === "custom"}
            >
              <FormLabel>Token</FormLabel>
              <Input
                value={token}
                onChange={({ target }) => setToken(target.value)}
              />
              {idState.value === "custom" ? (
                <FormHelperText>
                  A service token is required when specifying a custom tenant ID
                </FormHelperText>
              ) : (
                <FormHelperText>
                  Leave blank to use your user token
                </FormHelperText>
              )}
            </FormControl>
            {isProduction(current) && (
              <FormControl id="cm" mt={6}>
                <FormLabel>Change Management ID</FormLabel>
                <Input
                  value={cm}
                  onChange={({ target }) => setCm(target.value)}
                />
                <FormHelperText>
                  Leave blank to be able to view settings, but you won't be able
                  to make changes
                </FormHelperText>
              </FormControl>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="submit" colorScheme="blue" mt={4}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default Settings;
