const listOfPublishableConfigs = [
  "FraudGrouperConfig",
  "SafeprintBlacklistModelConfigs",
  "VoiceprintBlacklistModelConfigs",
  "CarrierRiskLabels",
  "EnsembleConfigs",
  "SafeLabelModelConfigs",
  "Level3EnsemblePredictorConfig",
  "AdaptiveListConfig",
  "SpoofprintModelConfigs",
  "ScoreReportingThresholdConfigs",
  "SafeExtractionConfigs",
  "SvarExtractionConfig",
  "SvarFactoryConfig",
];

export default listOfPublishableConfigs;
