import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  VStack,
  Heading,
  useColorMode,
  Divider,
  useToast,
} from "@chakra-ui/react";
import * as React from "react";
import { API } from "aws-amplify";
import jwtDecode from "jwt-decode";
import { EnvironmentContext } from "./EnvironmentProvider";

const login = async ({
  username,
  password,
  loginUrl,
}: {
  username: string;
  password: string;
  loginUrl: string;
}) => {
  const res = await API.post("proxyAPI", "/proxy", {
    queryStringParameters: {
      url: loginUrl,
    },
    body: {
      username,
      password,
    },
  });
  if (res.error) {
    throw new Error(res.error.message);
  }
  return res?.data?.login_token ?? "";
};

export const AuthContext = React.createContext({
  logout: () => {},
  username: "",
});

const Auth = ({
  children,
  onLogout,
}: {
  children: React.ReactElement;
  onLogout: () => void;
}) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [pw, setPw] = React.useState("");
  const [showPw, setShowPw] = React.useState(false);
  const [isSigningIn, setIsSigningIn] = React.useState(false);

  const { iam, setEndpoints } = React.useContext(EnvironmentContext);

  const iamLogin = `${iam}/login`;
  const iamLogout = `${iam}logout`;

  const logout = () => {
    setIsSignedIn(false);
    window.localStorage.removeItem("config-app-token");
    onLogout();
    API.post("proxyAPI", "/proxy", {
      queryStringParameters: {
        url: iamLogout,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem(
          "config-app-token"
        )}`,
      },
    });
  };

  React.useEffect(() => {
    const storedToken = window.localStorage.getItem("config-app-token");
    if (!storedToken) return;
    const decoded: { exp: number } = jwtDecode(storedToken);
    if (decoded?.exp > Date.now() / 1000) {
      setIsSignedIn(true);
    }
  }, []);

  if (!isSignedIn) {
    return (
      <Grid
        templateColumns="1fr"
        alignItems="center"
        justifyItems="center"
        h="full"
        bgGradient="linear(to-br, green.200, blue.700)"
      >
        <GridItem
          border="1px solid"
          borderColor="inherit"
          boxShadow="dark-lg"
          rounded="md"
          p={6}
          w="md"
          bgColor={colorMode === "dark" ? "gray.700" : "gray.50"}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setIsSigningIn(true);
              login({ username, password: pw, loginUrl: iamLogin })
                .then((token) => {
                  window.localStorage.setItem("config-app-token", token);
                  setIsSignedIn(true);
                })
                .catch((e) => {
                  toast({
                    status: "error",
                    title: "Unable to sign in",
                    description: "Incorrect username or password",
                    duration: 5000,
                    isClosable: true,
                  });
                })
                .finally(() => {
                  setIsSigningIn(false);
                });
            }}
          >
            <VStack spacing={8}>
              <FormControl id="environment-choice" isRequired>
                <FormLabel>Environment</FormLabel>
                <Select
                  placeholder="Select environment"
                  onChange={(e) => {
                    if (
                      e.target.value === "staging" ||
                      e.target.value === "pre-prod" ||
                      e.target.value === "production-2" ||
                      e.target.value === "ca-production" ||
                      e.target.value === "production"
                    ) {
                      setEndpoints(e.target.value);
                    }
                  }}
                >
                  <option value="staging">Staging</option>
                  <option value="pre-prod">Pre-Prod</option>
                  <option value="production">Production</option>
                  <option value="production-2">US Production 2</option>
                  <option value="ca-production">CA Production</option>
                </Select>
              </FormControl>
              <FormControl isRequired id="username">
                <FormLabel>Username</FormLabel>
                <Input
                  value={username}
                  onChange={({ target }) => setUsername(target.value)}
                />
              </FormControl>
              <FormControl isRequired id="pw">
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    value={pw}
                    onChange={({ target }) => setPw(target.value)}
                    type={showPw ? "text" : "password"}
                    pr="4.5rem"
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => setShowPw((cur) => !cur)}
                    >
                      {showPw ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button
                mt={3}
                type="submit"
                colorScheme="blue"
                isLoading={isSigningIn}
                variant="ghost"
                w="full"
              >
                Sign in with your cloud platform login
              </Button>
            </VStack>
          </form>
          <Divider my={4} />
          <Heading
            as="h1"
            size="sm"
            color={colorMode === "light" ? "blue.700" : "gray.100"}
            textAlign="center"
          >
            Pindrop
          </Heading>
        </GridItem>
      </Grid>
    );
  }

  return (
    <AuthContext.Provider value={{ logout, username }}>
      {children}
    </AuthContext.Provider>
  );
};

export default Auth;
