import * as React from "react";
import { ChakraProvider, Grid, GridItem, extendTheme } from "@chakra-ui/react";
import Auth from "./Auth";
import ConfigView from "./ConfigurationView/ConfigView";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Publish from "./Publish";
import EnvironmentProvider from "./EnvironmentProvider";
import "./App.css";
import Settings from "./Settings";
import TopBar from "./TopBar";
import SideNav from "./SideNav";
import listOfPublishableConfigs from "./publishedConfigs";
import Entitlements from "./Entitlements/Entitlements";
import useOrgLookup from "./useOrgLookup";

const theme = extendTheme({
  fonts: {
    heading: "Open Sans",
    body: "Open Sans",
  },
});

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <EnvironmentProvider>
        <Main />
      </EnvironmentProvider>
    </ChakraProvider>
  );
};

const Main = () => {
  const [configsToPublish, setConfigsToPublish] = React.useState<
    Record<string, Record<string, any>>
  >({});

  const addToConfigsToPublish = ({
    customerId,
    configType,
  }: {
    customerId: string;
    configType: string;
  }) => {
    if (listOfPublishableConfigs.includes(configType)) {
      setConfigsToPublish((cur) => {
        return {
          ...cur,
          [customerId]: {
            ...cur[customerId],
            [configType]: true,
          },
        };
      });
    }
  };
  const unpublishedConfigCount = Object.keys(configsToPublish).reduce(
    (acc, customerId) => {
      return acc + Object.keys(configsToPublish[customerId]).length;
    },
    0
  );

  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const [customerId, setCustomerId] = React.useState("");
  const [token, setToken] = React.useState("");
  const [cm, setCm] = React.useState("");
  const [blockProdSubmit, setBlockProdSubmit] = React.useState(false);

  const data = useOrgLookup({ orgId: customerId, token });

  React.useEffect(() => {
    if (!customerId) {
      setSettingsOpen(true);
    }
  }, [customerId]);

  return (
    <Auth
      onLogout={() => {
        setCustomerId("");
        setToken("");
      }}
    >
      <BrowserRouter>
        <Grid h="full" p={3} templateRows="auto minmax(0, 1fr)">
          <GridItem w="full">
            <TopBar
              customerId={customerId}
              customerName={data?.name}
              token={token}
              openSettings={() => setSettingsOpen(true)}
            />
          </GridItem>
          <GridItem>
            <Grid templateColumns="250px minmax(0, 1fr)" gap={3} h="full">
              <GridItem px={3} h="full">
                <SideNav publishCount={unpublishedConfigCount} />
              </GridItem>
              <GridItem h="full" overflow="auto">
                <Switch>
                  <Route path="/configuration">
                    <ConfigView
                      addToConfigsToPublish={addToConfigsToPublish}
                      customerId={customerId}
                      cm={cm}
                      setCustomerId={setCustomerId}
                      token={token}
                      setToken={setToken}
                      blockProdSubmit={blockProdSubmit}
                    />
                  </Route>
                  <Route path="/publish">
                    <Publish
                      configsToPublish={configsToPublish}
                      clear={() => setConfigsToPublish({})}
                    />
                  </Route>
                  <Route path="/entitlements">
                    <Entitlements
                      entitlementIds={data?.entitlements}
                      token={token}
                      customerId={customerId}
                      blockProdSubmit={blockProdSubmit}
                    />
                  </Route>
                  <Route path="*">
                    <Redirect to="/configuration" />
                  </Route>
                </Switch>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
        <Settings
          customerId={customerId}
          setCustomerId={setCustomerId}
          token={token}
          setToken={setToken}
          isOpen={settingsOpen}
          onClose={() => setSettingsOpen(false)}
          cm={cm}
          setCm={setCm}
          setBlockProdSubmit={setBlockProdSubmit}
        />
      </BrowserRouter>
    </Auth>
  );
};
