import * as React from "react";
import AceEditor, { IEditorProps } from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Link,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import ace from "ace-builds/src-noconflict/ace";
import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { EnvironmentContext } from "../EnvironmentProvider";
import ConfigSettings from "./ConfigSettings";
import { AuthContext } from "../Auth";

ace.config.set(
  "basePath",
  "https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/"
);

const loadConfig = async ({
  configType,
  customerId,
  apiUrl,
  token,
}: {
  configType: string;
  customerId: string;
  apiUrl: string;
  token?: string;
}) => {
  const loginToken = window.localStorage.getItem("config-app-token");
  const { data = {}, error } = await API.get("proxyAPI", "/proxy", {
    headers: {
      Authorization: `Bearer ${token || loginToken}`,
    },
    queryStringParameters: {
      url: `${apiUrl}/configservice/${configType}/${customerId}`,
    },
  });
  if (error) {
    throw new Error(error.message);
  }
  return data[customerId];
};

const createAuditRecord = async ({
  customerId,
  cm,
  environment,
  user,
  configName,
}: {
  configName: string;
  customerId: string;
  cm: string;
  environment: string;
  user: string;
}) => {
  API.graphql({
    query: mutations.createProdChange,
    variables: {
      input: {
        customerId,
        cm,
        environment,
        user,
        configName,
      },
    },
  });
};

const submitConfig = async ({
  configType,
  customerId,
  data,
  apiUrl,
  token,
}: {
  configType: string;
  customerId: string;
  data: Record<string, any>;
  apiUrl: string;
  token?: string;
}) => {
  const loginToken = window.localStorage.getItem("config-app-token");
  const { error } = await API.put("proxyAPI", "/proxy", {
    headers: {
      Authorization: `Bearer ${token || loginToken}`,
    },
    queryStringParameters: {
      url: `${apiUrl}/configservice/${configType}/${customerId}`,
    },
    body: {
      data,
    },
  });
  if (error) {
    throw new Error(error.message);
  }
  return;
};

interface ConfigViewProps {
  addToConfigsToPublish: ({
    customerId,
    configType,
  }: {
    customerId: string;
    configType: string;
  }) => void;
  customerId: string;
  token?: string;
  setCustomerId: (val: string) => void;
  setToken: (val: string) => void;
  blockProdSubmit: boolean;
  cm?: string;
}

const ConfigView = ({
  addToConfigsToPublish,
  customerId,
  token,
  blockProdSubmit,
  cm,
}: ConfigViewProps) => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const { api, current } = React.useContext(EnvironmentContext);
  const { username } = React.useContext(AuthContext);
  const [
    AceEditorInstance,
    setAceEditorInstance,
  ] = React.useState<IEditorProps>({});
  const [configType, setConfigType] = React.useState("");
  const [isLoadingConfig, setIsLoadingConfig] = React.useState(false);
  const [isSettingConfig, setIsSettingConfig] = React.useState(false);
  const [editorContents, setEditorContents] = React.useState("");

  const handleLoadConfig = () => {
    if (!configType || !customerId) return;
    setEditorContents("");
    setIsLoadingConfig(true);
    loadConfig({ configType, customerId, apiUrl: api, token })
      .then((config) => {
        AceEditorInstance.setValue(JSON.stringify(config, null, "\t"));
      })
      .catch((e) => {
        if (e.message.includes("404")) {
          toast({
            status: "info",
            duration: 7000,
            isClosable: true,
            description: "Config not found",
          });
        }
      })
      .finally(() => {
        setIsLoadingConfig(false);
      });
  };

  return (
    <Grid templateColumns="1fr 300px" gap={6} h="full">
      <GridItem>
        <Box mb={3}>
          <Link
            href={`https://github.atl.pdrop.net/pages/jgrim/proto-doc/#pindrop.ivr_auth.${configType}`}
            isExternal
            color={colorMode === "light" ? "blue.500" : "blue.400"}
          >
            {configType
              ? `View ${configType} documentation`
              : "View documentation"}
          </Link>
        </Box>
        <AceEditor
          mode="json"
          theme={colorMode === "light" ? "github" : "monokai"}
          name="code-editor"
          onLoad={(editor): void => setAceEditorInstance(editor)}
          fontSize={14}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          setOptions={{
            showLineNumbers: true,
            tabSize: 2,
          }}
          width="100%"
          value={editorContents}
          onChange={(val) => setEditorContents(val)}
        />
        <Button
          colorScheme="teal"
          w="full"
          mt={4}
          isLoading={isSettingConfig}
          isDisabled={!editorContents || blockProdSubmit}
          onClick={() => {
            const annotations = AceEditorInstance.getSession().getAnnotations();
            if (annotations.length > 0) {
              toast({
                status: "info",
                duration: 7000,
                isClosable: true,
                description:
                  "Unable to proceed. Please check the editor for errors.",
              });
              return;
            }

            setIsSettingConfig(true);
            if (cm) {
              createAuditRecord({
                configName: configType,
                customerId,
                environment: current,
                user: username,
                cm,
              });
            }
            submitConfig({
              configType,
              customerId,
              data: JSON.parse(editorContents),
              apiUrl: api,
              token,
            })
              .then(() => {
                addToConfigsToPublish({ configType, customerId });
                toast({
                  status: "success",
                  duration: 7000,
                  isClosable: true,
                  description: "Configuration updated!",
                });
              })
              .catch((e) => {
                toast({
                  status: "error",
                  duration: 7000,
                  isClosable: true,
                  description: e.message,
                });
              })
              .finally(() => {
                setIsSettingConfig(false);
              });
          }}
        >
          Submit config
        </Button>
      </GridItem>
      <GridItem>
        <ConfigSettings
          configType={configType}
          setConfigType={setConfigType}
          loadConfig={handleLoadConfig}
          isLoadingConfig={isLoadingConfig}
        />
      </GridItem>
    </Grid>
  );
};

export default ConfigView;
