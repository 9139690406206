import * as React from "react";
import { FormControl, FormLabel, Select, Button } from "@chakra-ui/react";
import getConfigs from "./configs";

interface ConfigSettingsProps {
  configType: string;
  setConfigType: (val: string) => void;
  loadConfig: () => void;
  isLoadingConfig: boolean;
}

const ConfigSettings = ({
  configType,
  setConfigType,
  loadConfig,
  isLoadingConfig,
}: ConfigSettingsProps) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        loadConfig();
      }}
    >
      <FormControl id="config-type" isRequired>
        <FormLabel>Configuration Type</FormLabel>
        <Select
          placeholder="Select config type"
          value={configType}
          onChange={(e) => setConfigType(e.target.value)}
        >
          {getConfigs().map((config) => (
            <option key={config} value={config}>
              {config.replace("Config", "")}
            </option>
          ))}
        </Select>
      </FormControl>
      <Button
        type="submit"
        colorScheme="blue"
        w="full"
        mt={4}
        isLoading={isLoadingConfig}
      >
        Load Current Value
      </Button>
    </form>
  );
};

export default ConfigSettings;
