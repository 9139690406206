import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  VStack,
  Text,
  useToast,
  useColorMode,
  Spacer,
  Flex,
} from "@chakra-ui/react";
import { API } from "aws-amplify";
import * as React from "react";
import { EnvironmentContext } from "./EnvironmentProvider";

interface PublishProps {
  configsToPublish: Record<string, Record<string, any>>;
  clear: () => void;
}

const Publish = ({ configsToPublish, clear }: PublishProps) => {
  const { colorMode } = useColorMode();
  const background =
    colorMode === "light"
      ? { boxShadow: "lg", borderColor: "gray.100" }
      : { borderColor: "inherit" };
  const toast = useToast();
  const { api } = React.useContext(EnvironmentContext);
  const customerIds = Object.keys(configsToPublish);

  if (customerIds.length === 0) {
    return (
      <Box>
        <Text fontSize="lg">
          You have not edited any configurations which need to be published
        </Text>
      </Box>
    );
  }

  return (
    <Box h="full">
      <Flex direction="column" h="full">
        <Grid templateColumns="1fr 1fr 1fr" gap={6} align="center">
          {customerIds.map((customerId) => {
            return (
              <GridItem
                key={customerId}
                border="1px solid"
                borderRadius="md"
                py={3}
                {...background}
              >
                <Text fontWeight="600" fontSize="lg">
                  {customerId}
                </Text>
                <VStack spacing={3} mt={4}>
                  {Object.keys(configsToPublish[customerId]).map((config) => (
                    <Text key={`${customerId}-${config}`}>{config}</Text>
                  ))}
                </VStack>
              </GridItem>
            );
          })}
        </Grid>
        <Spacer />
        <Center mt={4}>
          <Button
            colorScheme="teal"
            w="xl"
            onClick={() => {
              const promises = customerIds.map((id) =>
                API.post("proxyAPI", "/proxy", {
                  queryStringParameters: {
                    url: `${api}/configservice/publish/${id}`,
                  },
                  headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                      "config-app-token"
                    )}`,
                  },
                })
              );
              Promise.allSettled(promises).then(() => {
                toast({
                  status: "success",
                  duration: 7000,
                  isClosable: true,
                  description: "Configuration changes published",
                });
                clear();
              });
            }}
          >
            Publish
          </Button>
        </Center>
      </Flex>
    </Box>
  );
};

export default Publish;
