import * as React from "react";
import { API } from "aws-amplify";
import jwtDecode from "jwt-decode";
import { EnvironmentContext } from "./EnvironmentProvider";

interface User {
  assigned_orgs: {
    id: string;
    name: string;
  }[];
}

const getUser = async (iamUrl: string) => {
  const token = window.localStorage.getItem("config-app-token");
  if (!token) return;
  const { sub }: { sub: string } = jwtDecode(token);
  const [userId] = sub.split("@");
  const { data } = await API.get("proxyAPI", "/proxy", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    queryStringParameters: {
      url: `${iamUrl}/user/${userId}`,
    },
  });
  return data;
};

export default function useUserLookup() {
  const { iam } = React.useContext(EnvironmentContext);
  const [data, setData] = React.useState<User>();
  React.useEffect(() => {
    getUser(iam)
      .then((data) => {
        setData(data);
      })
      .catch((e) => console.warn(e));
  }, [iam]);

  return data;
}
