import * as React from "react";
import { API } from "aws-amplify";
import { EnvironmentContext } from "./EnvironmentProvider";

interface Org {
  id: string;
  name: string;
  entitlements: string[];
}

export const getOrg = async ({
  iamUrl,
  orgId,
  token,
}: {
  iamUrl: string;
  orgId: string;
  token?: string;
}) => {
  const tokenForUse = token || window.localStorage.getItem("config-app-token");
  if (!tokenForUse) return;
  const { data } = await API.get("proxyAPI", "/proxy", {
    headers: {
      Authorization: `Bearer ${tokenForUse}`,
    },
    queryStringParameters: {
      url: `${iamUrl}/org/${orgId}`,
    },
  });
  return data;
};

export default function useOrgLookup({
  orgId,
  token,
}: {
  orgId?: string;
  token?: string;
}) {
  const { iam } = React.useContext(EnvironmentContext);
  const [data, setData] = React.useState<Org>();
  React.useEffect(() => {
    if (!orgId) return;
    getOrg({ orgId, iamUrl: iam, token })
      .then((data) => {
        setData(data);
      })
      .catch((e) => console.warn(e));
  }, [iam, orgId, token]);

  return data;
}
